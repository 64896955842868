import * as React from 'react';

class Home extends React.Component<{}> {
    public render() {
        return (
            <div className="home-container">
                
            </div>
        );
    }
}

export default Home;